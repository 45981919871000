.hr-alljobs-content-container {
    padding-top: 60px;
}

.hr-alljobs-details-container {
    background-color: #fff;
}

/* ALL Job */

.hr-alljobs-container {
    background-color: #1967d21a;
    border-radius: 7px;
}


.hr-alljobs-items-container {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;

}


.hr-alljobs-items-para {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    border-right: 1px solid lightgray;
}

.hr-alljobs-items-para1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.hr-alljobs-items-img-container {
    height: 45px;
}

.hr-alljobs-items-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.hr-jobpost-expired-text-div {
    color: #d93025;
    background: rgba(217, 48, 37, .1);
    padding: 0px 11px;
    border-radius: 50px;
}


.hr-jobpost-active-text-div {
    background: rgba(52, 168, 83, .15);
    color: #34a853;
    padding: 0px 11px;
    border-radius: 50px;
}

.hr-alljobs-items-days {
    margin-top: 13px;
}