.block-profile-card-container {
    background-color: #1967d21a;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.block-profile-card-img {
    height: 240px;
    width: 190px;
    object-fit: cover;
    border-radius: 10px;
}

.block-profile-detail-card-heading {
    color: #4B4F58;
}

.block-profile-detail-card-aadhar-span {
    background-color: rgba(25, 103, 210, .15);
    color: #1967d2;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    letter-spacing: 0.3px;
}

.block-profile-detail-card-pan-span {
    background: rgba(52, 168, 83, .15);
    color: #34a853;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    letter-spacing: 0.3px;
}

.block-profile-detail-card-dateofbirth-span {
    background: rgba(249, 171, 0, .15);
    color: #f9ab00;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    letter-spacing: 0.3px;
}

.block-profile-detail-card-uin-span {
    background-color: rgba(210, 173, 25, 0.15);
    color: #9d9f18;
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    letter-spacing: 0.3px;
}

.block-profile-detail-card-delete {
    background-color: rgba(255, 0, 0, 0.15);
    color: #e91212;
    border-radius: 50px;
    cursor: pointer;
}

.block-profile-detail-card-update {
    background-color: rgba(25, 103, 210, .15);
    color: #1967d2;
    border-radius: 50px;
    cursor: pointer;
}