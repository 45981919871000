.contact-us-main-container {
    width: 100%;
    background-color: #fafafa;
}

.contact-us-img {
    width: 100%;
    object-fit: cover;
    height: 400px;
    border-radius: 5px;
    animation: contact-img 5s ease-out infinite;
}

@keyframes contact-img {
    0% {
        transform: translateY(15px);
    }

    50% {
        transform: translateY(0px);

    }

    100% {
        transform: translateY(15px);
    }
}

.contact-container-small-heading {
    font-weight: bold;
    letter-spacing: 0.5px;
}

.contact-container-para {
    color: gray;
    letter-spacing: 0.3px;
    font-size: 14.5px;
    line-height: 25px;
}

.contact-container2-para {
    letter-spacing: 0.3px;
    font-weight: 600;
    color: rgb(93, 93, 93);
}

.contact-us-main-input-container {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: #fff;
    border-radius: 15px;
}

.contact-input {
    font-size: 17px;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    outline: none;
    border: 1px solid #fff;
    box-shadow: none;
    background-color: #f6f6f6;
    margin: 14px 0px;
}

.contact-input:focus {
    border: 1px solid green;
}

.contact-us-small-heading {
    font-weight: 600;
    letter-spacing: 0.5px;
}

.contact-us-main-heading {
    font-weight: 600;
    letter-spacing: 0.5px;
}

.primary-button {
    font-family: 'Ropa Sans', sans-serif;
    margin-top: 10px;
    color: white;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.05rem;
    border: 1px solid #0E1822;
    padding: 0.7rem 2.1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 531.28 200'%3E%3Cdefs%3E%3Cstyle%3E .shape %7B fill: %23FF4655 /* fill: %230E1822; */ %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolygon class='shape' points='415.81 200 0 200 115.47 0 531.28 0 415.81 200' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-color: #0E1822;
    background-size: 200%;
    background-position: 200%;
    background-repeat: no-repeat;
    transition: 0.3s ease-in-out;
    transition-property: background-position, border, color;
    position: relative;
    z-index: 1;
}

.primary-button:hover {
    border: 1px solid #FF4655;
    color: white;
    background-position: 40%;
}

.primary-button:before {
    content: "";
    position: absolute;
    background-color: #0E1822;
    width: 0.2rem;
    height: 0.2rem;
    top: -1px;
    left: -1px;
    transition: background-color 0.15s ease-in-out;
}

.primary-button:hover:before {
    background-color: white;
}

.primary-button:hover:after {
    background-color: white;
}

.primary-button:after {
    content: "";
    position: absolute;
    background-color: #FF4655;
    width: 0.3rem;
    height: 0.3rem;
    bottom: -1px;
    right: -1px;
    transition: background-color 0.3s ease-in-out;
}

.button-borders {
    position: relative;
    width: fit-content;
    height: fit-content;
}

.button-borders:before {
    content: "";
    position: absolute;
    width: calc(100% + 0.5em);
    height: 50%;
    left: -0.3em;
    top: -0.3em;
    border-bottom: 0px;
    /* opacity: 0.3; */
}

.button-borders:after {
    content: "";
    position: absolute;
    width: calc(100% + 0.5em);
    height: 50%;
    left: -0.3em;
    bottom: -0.3em;
    border-top: 0px;
    /* opacity: 0.3; */
    z-index: 0;
}

.shape {
    fill: #0E1822;
}