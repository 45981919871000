.tpo-manage-sub-container {
    background-color: #1967d21a;
    border-radius: 10px;
}

.tpo-manage-sub-card {
    border-radius: 7px;
}

.tpo-manage-sub-card-dashboard {
    border-radius: 7px;
    border: 1px solid lightgray;
}