.hr-dashboard-details-container {
    background-color: #fff;
}

.hr-dashboard-content-container {
    padding-top: 60px;
}

.hr-dashboard-count-sub-card {
    background-color: #1967d21a;
    border-radius: 7px;
}

.hr-dashboard-postjob-count {
    font-weight: 600;
    color: #1967d2;
    letter-spacing: 0.3px;
}

.hr-dashboard-icon-container {
    background-color: rgba(25, 103, 210, .1);
    border-radius: 7px;
}

.hr-dashboard-application-count {
    font-weight: 600;
    color: #d93025;
    letter-spacing: 0.3px;
}

.hr-dashboard-icon1-container {
    background-color: rgba(217, 48, 37, .1);
    border-radius: 7px;
}

.hr-dashboard-shortlist-count {
    font-weight: 600;
    color: #34a853;
    letter-spacing: 0.3px;
}

.hr-dashboard-icon2-container {
    background-color: rgba(52, 168, 83, .1);
    border-radius: 7px;
}

/* Recent Job */
.hr-dashboard-recent-jobs-container {
    background-color: #fff;
    border-radius: 7px;
}


.hr-dashboard-recentjobs-items-container {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;

}


.hr-dashboard-recentjobs-items-para {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    border-right: 1px solid lightgray;
}

.hr-dashboard-recentjobs-items-para1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.hr-dashboard-recentjobs-items-img-container {
    height: 45px;
}

.hr-dashboard-recentjobs-items-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.hr-jobpost-expired-text-div {
    color: #d93025;
    background: rgba(217, 48, 37, .1);
    padding: 0px 11px;
    border-radius: 50px;
}

.hr-jobpost-active-text-div {
    background: rgba(52, 168, 83, .15);
    color: #34a853;
    padding: 0px 11px;
    border-radius: 50px;
}

.hr-dashboard-recentjobs-items-days {
    margin-top: 13px;
}

.student-dashboard-dreamjob-container {
    background-color: transparent;
    height: 500px;
    width: 100%;
}

.student-dashboard-dreamjob-jobs-container,
.student-dashboard-dreamjob1-container {
    width: 100%;
    height: 100%;

}

.student-dashboard-dreamjob-jobs-heading {
    font-size: 210px;
    font-weight: bold;
    color: #1967d21a;
}

.find-your-heading {
    font-size: 65px;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 480px) {
    .find-your-heading {
        font-size: 47px;
        letter-spacing: 0.5px;
    }
}

.student-dashboard-dreamjob-jobs-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.student-dashboard-dreamjob1-para {
    color: gray;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.student-dashboard-dreamjob-jobs-img1 {
    object-fit: cover;
    width: 80%;
    height: 70%;
}

.student-dashboard-talent-container {
    background-color: transparent;
}

.student-dashboard-talent-img {
    object-fit: cover;
    width: 100%;
}

.student-dashboard-popular-container {
    border-radius: 10px;
}

.student-dashboard-talent-heading {
    color: #00044A;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 40px;
}

.student-dashboard-talent-para {
    color: gray;
    letter-spacing: 0.3px;
}

.popular-jobs-card-container {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid lightgray;
    cursor: pointer;
}

.popular-jobs-card-img {
    background-color: #ecedf2;
    border-radius: 10px;
}

.popular-jobs-card-container:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: ease 0.4s all;
}

.student-dashboard-top-jobs-heading {
    color: #000DFF;
    font-weight: 600;
    font-size: 100px;
}

.student-dashboard-top-jobs-sub-heading {
    color: #00044A;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.student-dashboard-top-jobs-para {
    color: gray;
    letter-spacing: 0.2px;
    line-height: 25px;
}

.student-dashboard-millions-container {
    background-color: #fff;
    border-radius: 10px;
}

.student-dashboard-millions-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.student-dashboard-howtoapply-container {
    height: 500px;
    width: 100%;

}

.student-dashboard-howtoapply-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.student-dashboard-howtoapply-img-container {
    width: 100%;
    height: 100%;
}

.student-dashboard-howtoapply-text-img {
    object-fit: cover;
    width: 90px;
    height: 90px;
}

.student-dashboard-howtoapply-text-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.student-dashboard-howtoapply-text-card {
    backdrop-filter: blur(100px);
    background-color: #26317f;
    border-radius: 10px;
}