@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&family=Pacifico&family=Poppins&display=swap');



.student-login-container {
    background-image: url('https://img.freepik.com/free-photo/assortment-teacher-s-day-elements_23-2149044959.jpg?w=900&t=st=1707121285~exp=1707121885~hmac=b99154ab542737e4450bf35adbc99b42cb0011dd35edac51d003eb1cd7c4a08b');
    /* background-image: url('C:\Users\YSK-IT-1002\Desktop\REACT\HRPC_FRONT_END\forms\src\Images\images\Student_Component\student_login_bac.jpg'); */
    background-size: cover;
    width: 100%;
}

.student-login-main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.student-login-heading {
    text-align: center;
    margin-bottom: 25px;
    font-weight: 800;
    letter-spacing: 1px;
}


.student-login-card-container {
    background-color: white;
    max-width: 420px;
    padding: 30px;
    margin: 0px auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}

.student-login-input-container {
    background-color: transparent;
    display: flex;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 50px;
}

.student-login-input-container:focus-within {
    border: 1px solid green;
    transition: ease 0.5s all;
    background-color: transparent;
}

.student-login-input-icon-container {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student-login-input-icon {
    font-size: 25px;
}

.student-login-input {
    outline: none;
    border: none;
    box-shadow: none;
    font-size: 17px;
    width: 100%;
    padding-left: 10px;
    background-color: transparent;
}

.student-login-forget-password-para {
    cursor: pointer;
    text-decoration: underline;
    text-align: end;
    font-size: 12px;
    font-weight: 700;
    color: gray;
}

.student-login-forget-password-para:hover {
    color: #000;
}

.student-login-btn {
    background: teal;
    color: white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 50px;
    margin-top: 35px;
}

.student-or-login-para {
    color: gray;
    font-size: 13px;
    text-align: center;
}

.student-login-google-button {
    font-size: 13px;
}

.student-login-google-button {
    border: 2px solid gray;
    background: transparent;
    color: #000;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    outline: none;
    width: 100%;
    border-radius: 50px;
}