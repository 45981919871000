.page-loader-main-container {
    height: 70vh;
}

.page-loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    position: relative;
    animation: page-loader-spin 2s linear infinite;
}

.page-loader .page-circle {
    --color: #000;
    --dim: 1.2rem;
    width: var(--dim);
    height: var(--dim);
    background-color: var(--color);
    border-radius: 5px;
    position: absolute;
}

.page-loader .page-circle:nth-child(1) {
    top: 0;
    left: 0;
}

.page-loader .page-circle:nth-child(2) {
    top: 0;
    right: 0;
}

.page-loader .page-circle:nth-child(3) {
    bottom: 0;
    left: 0;
}

.page-loader .page-circle:nth-child(4) {
    bottom: 0;
    right: 0;
}

@keyframes page-loader-spin {
    0% {
        transform: scale(1) rotate(0);
    }

    20%,
    25% {
        transform: scale(1.3) rotate(90deg);
    }

    45%,
    50% {
        transform: scale(1) rotate(180deg);
    }

    70%,
    75% {
        transform: scale(1.3) rotate(270deg);
    }

    95%,
    100% {
        transform: scale(1) rotate(360deg);
    }
}