.hr-sidebar-main-container {
    height: 100%;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #b4dcde;
}

.hr-sidebar-items-pre {
    font-size: 15px;
    padding-left: 15px;
    padding-top: 1.3px;
    color: #474d6a;
}

.hr-sidebar-list-item {
    cursor: pointer;
    border-radius: 5px;
    padding: 11px;
}

.hr-sidebar-list-item:hover {
    background-color: rgba(25, 103, 210, .1);
    transition: ease-in-out 0.3s all;

}

.hr-sidebar-list-icon {
    margin-left: 5px;
}

.hr-sidebar-list-item:hover .hr-sidebar-items-pre {
    color: #1967d2 !important;
}

.hr-sidebar-list-item:hover .hr-sidebar-list-icon {
    color: #1967d2 !important;
}

.active_path {
    background-color: rgba(25, 103, 210, .1);
}

.active_path .hr-sidebar-items-pre {
    color: #1967d2 !important;
}

.active_path .hr-sidebar-list-icon {
    color: #1967d2 !important;
}