.hr-view-details-container {
    background-color: #fff;
}

.hr-view-details-title-container {
    width: 100%;
    height: 150px;
}

.hr-view-details-title-img-container {
    width: 100%;
    height: 150px;
}

.hr-view-details-title-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hr-view-details-title-heading-container {
    width: 100%;
    height: 150px;
    background-color: rgba(31, 43, 123, 0.8);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hr-view-details-title-heading {
    color: #fff;
}

.hr-view-details-company-card {
    background-color: #f5f7fc;
}

.hr-view-details-company-card-img {
    height: 70px;
    object-fit: cover;
    margin-bottom: 5px;
}

.hr-view-details-company-card-text {
    font-size: 14px;
    padding-top: 3px;
    margin-right: 20px !important;
}

.hr-view-details-employement-type {
    background-color: rgba(25, 103, 210, .15);
    color: #1967d2;
    font-size: 14px;
    padding: 2px 15px;
    border-radius: 50px;
    border: none;
    outline: none;
}

.hr-view-details-department-type {
    background: rgba(52, 168, 83, .15);
    color: #34a853;
    font-size: 14px;
    padding: 2px 15px;
    border-radius: 50px;
    border: none;
    outline: none;
}

.hr-view-details-shift-type {
    background: rgba(249, 171, 0, .15);
    color: #f9ab00;
    font-size: 14px;
    padding: 2px 15px;
    border-radius: 50px;
    border: none;
    outline: none;
}

/* Apply Button */

.hr-job-view-apply-for-job-btn {
    position: relative;
    transition: all 0.3s ease-in-out;
    /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: rgb(0 107 179);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 8px;
}

.hr-job-view-apply-for-job-btn-icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.hr-job-view-apply-for-job-btn:hover {
    transform: scale(1.05);
    border-color: #fff9;
}

.hr-job-view-apply-for-job-btn:hover .hr-job-view-apply-for-job-btn-icon {
    transform: translate(4px);
}

.hr-job-view-apply-for-job-btn:hover::before {
    animation: btn-shine 1.5s ease-out infinite;
}

.hr-job-view-apply-for-job-btn::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes btn-shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}

.hr-view-job-des-inner-html {
    color: #474d6a;
    line-height: 27px;
    font-size: 14px;
}

.he-view-job-overview-container {
    background-color: #f5f7fc;
    border-radius: 10px;
}


.hr-view-company-card-img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
}