.hr-nav-logo {
    border-radius: 50px;
    width: 50px !important;
}

.hr-nav-lg-container {
    background-color: #fff;
    height: 70px;
    padding: 0px 50px 0px 35px;
    box-shadow: 0px 0px 10px 0px #b4dcde;
}

@media screen and (max-width: 480px) {
    .hr-nav-lg-container {
        padding: 0px 25px 0px 10px;
    }
}