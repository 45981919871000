.hr-dashboard-details-container {
    background-color: #1967d21a;
}

.hr-dashboard-content-container {
    padding-top: 60px;
}

.hr-dashboard-count-sub-card {
    background-color: #fff !important;
    border-radius: 7px;
    border: 1px solid lightgray;
}

.hr-dashboard-postjob-count {
    font-weight: 600;
    color: #1967d2;
    letter-spacing: 1px;
}

.hr-dashboard-icon-container {
    background-color: rgba(25, 103, 210, .1);
    border-radius: 7px;
}

.hr-dashboard-application-count {
    font-weight: 600;
    color: #d93025;
    letter-spacing: 1px;
}

.hr-dashboard-icon1-container {
    background-color: rgba(217, 48, 37, .1);
    border-radius: 7px;
}

.hr-dashboard-shortlist-count {
    font-weight: 600;
    color: #34a853;
    letter-spacing: 1px;
}

.hr-dashboard-icon2-container {
    background-color: rgba(52, 168, 83, .1);
    border-radius: 7px;
}

/* Recent Job */
.hr-dashboard-recent-jobs-container {
    background-color: #1967d21a !important;
    border-radius: 7px;
}

.hr-dashboard-recent-jobs-container1 {
    border-radius: 7px;
}


.hr-dashboard-recentjobs-items-container {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;

}


.hr-dashboard-recentjobs-items-para {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    border-right: 1px solid lightgray;
}

.hr-dashboard-recentjobs-items-para1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.hr-dashboard-recentjobs-items-img-container {
    height: 45px;
}

.hr-dashboard-recentjobs-items-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.hr-jobpost-expired-text-div {
    color: #d93025;
    background: rgba(217, 48, 37, .1);
    padding: 0px 11px;
    border-radius: 50px;
}

.hr-jobpost-active-text-div {
    background: rgba(52, 168, 83, .15);
    color: #34a853;
    padding: 0px 11px;
    border-radius: 50px;
}

.hr-dashboard-recentjobs-items-days {
    margin-top: 13px;
}