@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&family=Pacifico&family=Poppins&display=swap');



.hr-login-container {
    background-image: url('https://img.freepik.com/free-photo/elevated-view-laptop-earphones-crumpled-papers-pencil-blank-spiral-notepad-frame-black-background_23-2148080331.jpg?w=900&t=st=1707121018~exp=1707121618~hmac=793ee3e4487bf8e8fca3aacad4a7abf99b6ea351ab269b6c008ac97dd0751119');
    background-size: cover;
    width: 100%;
}

.hr-login-main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hr-login-heading {
    text-align: center;
    margin-bottom: 25px;
    font-weight: 800;
    letter-spacing: 1px;
}


.hr-login-card-container {
    background-color: white;
    max-width: 420px;
    padding: 30px;
    margin: 0px auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}

.hr-login-input-container {
    background-color: transparent;
    display: flex;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 50px;
}

.hr-login-input-container:focus-within {
    border: 1px solid green;
    transition: ease 0.5s all;
    background-color: transparent;
}

.hr-login-input-icon-container {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hr-login-input-icon {
    font-size: 25px;
}

.hr-login-input {
    outline: none;
    border: none;
    box-shadow: none;
    font-size: 17px;
    width: 100%;
    padding-left: 10px;
    background-color: transparent;
}

.hr-login-forget-password-para {
    cursor: pointer;
    text-decoration: underline;
    text-align: end;
    font-size: 12px;
    font-weight: 700;
    color: gray;
}

.hr-login-forget-password-para:hover {
    color: #000;
}

.hr-login-btn {
    background: teal;
    color: white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 50px;
    margin-top: 35px;
}

.hr-or-login-para {
    color: gray;
    font-size: 13px;
    text-align: center;
}

.hr-login-google-button {
    font-size: 13px;
}

.hr-login-google-button {
    border: 2px solid gray;
    background: transparent;
    color: #000;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    outline: none;
    width: 100%;
    border-radius: 50px;
}