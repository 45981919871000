.about-main-container {
    width: 100%;
    background-color: #fafafa;
}


.about-us-img {
    width: 100%;
    object-fit: cover;
    height: 400px;
    border-radius: 5px;
    animation: about-img 5s ease-out infinite;
}

@keyframes about-img {
    0% {
        transform: translateY(15px);
    }

    50% {
        transform: translateY(0px);

    }

    100% {
        transform: translateY(15px);
    }
}

.about-container-small-heading {
    font-weight: bold;
    letter-spacing: 0.5px;
}

.about-container-para {
    color: gray;
    letter-spacing: 0.3px;
    font-size: 14.5px;
    line-height: 25px;
}

.about-container2-para {
    letter-spacing: 0.3px;
    font-weight: 600;
    color: rgb(93, 93, 93);
}