.admin-hr-view-container {
    background-color: #f0f2f5;
    width: 100%;
}

.admin-hr-view-sub-container {
    height: 500px;
    /* background-color: red; */
}

.admin-pages-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.admin-pages-icon-color {
    border-radius: 20px;
}

.admin-hr-view-text-container-1 {
    background-color: #f2f2f2;
}